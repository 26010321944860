<template>
  <div
    class="timeline-item"
    :class="{
      width: width,
      height: height,
      styleLoadingTech: styleLoadingTech
    }"
    :style="computedStyle"
  ></div>
</template>

<script>
export default {
  name: 'LoadingComponent',
  props: {
    width: {
      required: false
    },
    height: {
      required: false
    },
    styleLoadingTech: {
      required: false
    }
  },
  computed: {
    computedStyle() {
      if (window.innerWidth <= 500) {
        return {
          width: window.innerWidth - 55 + 'px'
        }
      }
      return {}
    }
  }
}
</script>
<style lang="scss" scoped>
.timeline-item {
  width: 100%;
  height: 100%;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: transparent;
  background: linear-gradient(to right, #f6f6f6 0%, #dbdbdb 25%, #f6f6f6 50%);
  background-size: 1000px 640px;
  &.width {
    width: 220px;
    height: 250%;
  }
  &.height {
    height: 77px;
    width: 192px;
  }
  &.styleLoadingTech {
    height: 77px;
    width: 100px;
    margin-top: 10px;
    margin-right: 3px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
